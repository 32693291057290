<template>
    <div class="all-view">
        <div class="main-page-title">

            <div style="width: 40px;height: 40px;position: relative">
                <img v-if="headerImg" :src="headerImg" style="width: 40px;height: 40px;border-radius: 6px;">
                <img v-else src="../../img/default_img.png" style="width: 40px;height: 40px;border-radius: 6px;">
            </div>

            <div class="top-info-view">
                <div style="font-weight: bold">{{userName}}</div>
                <div style="font-size: 11px;color: #dddddd;">{{uid}}</div>
            </div>

            <div class="top-info-view">
                <div style="font-weight: bold">七里币</div>
                <div style="font-size: 11px;color: #dddddd;">￥{{qlb}}</div>
            </div>

            <div class="top-info-view">
                <div style="font-weight: bold">手机号码</div>
                <div style="font-size: 11px;color: #dddddd;">{{tel || '未绑定'}}</div>
            </div>

            <div @click="close" style="width: 30px;height: 30px;position: absolute;right: 2px;top: 2px;background-color: rgba(0,0,0,0.1);box-sizing: border-box;border-radius: 50%;">
                <img src="../../img/close.png" style="width: 30px;height: 30px;">
            </div>

        </div>

        <div class="main-body">
            <div class="menu-view">
                <van-grid :border="true" :column-num="4">
                    <van-grid-item @click="goPage('gift')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/gift2.png" style="width: 30px;height: 30px;">
                        </div>
                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">礼包领取</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('jgg')" v-if="showLuckyDraw">
                          <div class="menu-img-view">
                            <img src="../../img/menu/draw.png" style="width: 28px;height: 28px;">
                          </div>
                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">幸运抽奖</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('record')" v-if="false">
                        <div class="menu-img-view">
                            <img src="../../img/menu/record.png" style="width: 30px;height: 30px;">
                        </div>
                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">消费查询</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('activity')">
                        <div class="menu-img-view">
<!--                            <img src="../../img/menu/record.png" style="width: 30px;height: 30px;">-->
                          <svg t="1725331644943" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8281" width="25" height="25">
                            <path d="M0 512c0 282.112 229.888 512 512 512 282.112 0 512-229.888 512-512a38.4 38.4 0 1 0-76.8 0v0.256A435.52 435.52 0 0 1 512 947.2 435.52 435.52 0 0 1 76.8 512c0-240.128 195.072-435.2 435.2-435.2A38.4 38.4 0 1 0 512 0C229.888 0 0 229.888 0 512z" fill="#feb30d" p-id="8282"></path>
                            <path d="M816.128 305.664V208.896h-98.304a36.736 36.736 0 0 1-36.864-36.864c0-20.48 16.384-36.864 36.864-36.864h98.304V36.864c0-20.48 16.384-36.864 36.864-36.864 20.48 0 36.864 16.384 36.864 36.864v98.304h97.28c20.48 0 36.864 16.384 36.864 36.864a37.056 37.056 0 0 1-36.864 36.864h-97.28v97.28c0 20.48-16.384 36.864-36.864 36.864a37.504 37.504 0 0 1-36.864-37.376zM659.968 512.512a36.48 36.48 0 0 0 36.352-36.352 36.48 36.48 0 0 0-36.352-36.352H548.864v-1.536l135.68-141.312a36.48 36.48 0 0 0-1.024-51.712 36.928 36.928 0 0 0-51.712 1.024L515.584 368.128 392.704 245.76a36.48 36.48 0 0 0-51.712 0 36.48 36.48 0 0 0-0.512 51.712l135.168 135.68v6.656H367.616a36.48 36.48 0 0 0-36.352 36.352 36.48 36.48 0 0 0 36.352 36.352h108.032v73.216H367.616a36.48 36.48 0 0 0-36.352 36.352 36.48 36.48 0 0 0 36.352 36.352h108.032v108.544a36.48 36.48 0 0 0 36.352 36.352 36.48 36.48 0 0 0 36.352-36.352v-108.544h111.104a36.48 36.48 0 0 0 36.352-36.352 36.48 36.48 0 0 0-36.352-36.352H548.352V512.512h111.616z" fill="#feb30d" p-id="8283"></path>
                          </svg>
                        </div>
                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">充值活动</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('strategy')">
                        <div class="menu-img-view">
                            <svg t="1721616722412" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="17823" width="25" height="25">
                                <path d="M705.8 256.4c-13.8 0-25-11.2-25-25V70.8H545.2v160.6c0 13.8-11.2 25-25 25s-25-11.2-25-25V45.8c0-13.8 11.2-25 25-25h185.6c13.8 0 25 11.2 25 25v185.6c0 13.8-11.2 25-25 25z" fill="#E99020" p-id="17824"></path>
                                <path d="M705.8 256.4c-5.7 0-11.4-1.9-16-5.8L613 186.2l-76.8 64.3c-10.6 8.9-26.3 7.5-35.2-3.1-8.9-10.6-7.5-26.3 3.1-35.2l92.8-77.8c9.3-7.8 22.8-7.8 32.1 0l92.8 77.8c10.6 8.9 12 24.6 3.1 35.2-4.9 5.9-12 9-19.1 9z" fill="#E99020" p-id="17825"></path>
                                <path d="M877.4 991.3H171.8c-49.1 0-89-39.9-89-89V109.8c0-49.1 39.9-89 89-89h705.6c49.1 0 89 39.9 89 89v792.4c0 49.1-39.9 89.1-89 89.1zM171.8 70.8c-21.5 0-39 17.5-39 39v792.4c0 21.5 17.5 39 39 39h705.6c21.5 0 39-17.5 39-39V109.8c0-21.5-17.5-39-39-39H171.8z" fill="#707070" p-id="17826"></path>
                                <path d="M281.6 991.3c-13.8 0-25-11.2-25-25V45.8c0-13.8 11.2-25 25-25s25 11.2 25 25v920.5c0 13.8-11.2 25-25 25zM725.9 463.1H500.1c-13.8 0-25-11.2-25-25s11.2-25 25-25h225.8c13.8 0 25 11.2 25 25s-11.2 25-25 25zM725.9 667.1H500.1c-13.8 0-25-11.2-25-25s11.2-25 25-25h225.8c13.8 0 25 11.2 25 25s-11.2 25-25 25zM776.9 565.1H449.1c-13.8 0-25-11.2-25-25s11.2-25 25-25h327.8c13.8 0 25 11.2 25 25s-11.2 25-25 25z" fill="#707070" p-id="17827"></path>
                            </svg>
                        </div>
                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">游戏攻略</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('gamespeed')" v-show="showGameSpeed">
                      <div class="menu-img-view">
                        <img src="../../img/menu/rocket.png" style="width: 26px;height: 26px;">
                      </div>
                      <span style="font-size: 12px;color: #444444;margin-top: 5px;">游戏加速</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('weixin')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/wechat.png" style="width: 30px;height: 30px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">绑定微信</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('kf')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/kf2.png" style="width: 26px;height: 26px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">联系客服</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('recharge')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/chong.png" style="width: 30px;height: 30px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">充值</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('tel')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/phone.png" style="width: 28px;height: 26px;">
                        </div>
                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">绑定手机</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('password')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/password2.png" style="width: 26px;height: 26px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">修改密码</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('app')" v-if="from === 'android'">
                        <div class="menu-img-view">
                            <img src="../../img/menu/qili-logo.png" style="width: 24px;height: 24px;border-radius: 3px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">七里APP</span>
                    </van-grid-item>
                    <van-grid-item @click="close">
                        <div class="menu-img-view">
                            <img src="../../img/menu/ys.png" style="width: 24px;height: 24px;border-radius: 3px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">返回游戏</span>
                    </van-grid-item>
                </van-grid>


                <div class="bottom-view">
                    <div style="display: flex;align-items: center;flex-direction: column" v-if="false">
                        <img src="../../img/main-round.png" style="width: 16px;height: 16px">
                        <span style="font-size: 10px;">七里网络</span>
                    </div>

                    <div style="display: flex;flex-direction: column;">
                        <span style="font-size: 15px;color: #4d4d4d;text-align: left">
                            <span style="font-family: myFont,serif;">更多游戏关注七里网络公众号</span>
                        </span>
                        <div style="text-align: left">
                            超多福利，超多精彩 sdk version: {{sdkVersion}}
                        </div>
                    </div>

                    <div @click="logout" style="width: 80px;;height: 25px;background-color: rgba(236,21,21,0.15);color: #ec2222;display: flex;align-items: center;justify-content: center;border-radius: 3px;font-weight: bold">
                        <img src="../../img/menu/logout.png" style="width: 12px;height: 12px;margin-right: 5px;">
                        <span>退出登录</span>
                    </div>
                </div>

            </div>
        </div>

        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
    import {request} from '@/api/request'
    import {Dialog, Toast} from "vant";
    import myloading from '@/components/myloading'
    import notice from '@/api/notice'
    import '../../css/commonpage.css'

    import Vue from 'vue';
    import { Grid, GridItem } from 'vant';
    Vue.use(Grid);
    Vue.use(GridItem);

    export default {
        name: "sdk-main",
        components: {
            myloading
        },
        data() {
          return {
              headerImg: '',
              userName: '',
              uid: '',
              tel: '',
              qlb: '0',
              serverMsg: '',
              showLoading: false,
              welfareCode: '',
              from: '',
              userInfo: {},
              gameJs: '',
              sdkVersion: '',
              showLuckyDraw: false,
              showGameSpeed: false,
              showJsButton: "0",
          }
        },
        mounted() {
            this.handleUrl();
            this.initPage();
            //this.addInputBlur();
        },
        methods: {
            addInputBlur() {
                let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // 检测是否为ios
                if(!isIOS) {
                    console.log('不是iOS，取消blur事件');
                    return ;
                }
                let t = document.getElementById('inputCode');
                t.removeEventListener('blur', function(){ window.scrollTo(0,0)});
                t.addEventListener('blur', function(){window.scrollTo(0,0)}, false)
            },
            handleUrl() {
                this.from = this.$route.query.from;
                let token = this.$route.query.token || '';
                let gameId = this.$route.query.gameId || '';
                this.gameJs = this.$route.query.gameJs || '';
                this.showJsButton = this.$route.query.showJsButton || "0";
                this.sdkVersion = this.$route.query.sdkVersion || '';
                if (!token) {
                    Toast('当前登录状态已失效，请重新登录游戏');
                    return ;
                }
                if (!gameId) {
                    Toast('游戏参数非法');
                    return ;
                }

                if (this.sdkVersion === '4.0-h') {
                    if (gameId === '2043') {
                        gameId = '390';
                    }
                    else if (gameId === '1820') {
                        gameId = '391';
                    }
                    else if (gameId === '1919') {
                        gameId = '392';
                    }
                    else if (gameId === '1654') {
                        gameId = '394';
                    }
                    else if (gameId === '2142') {
                        gameId = '400';
                    }
                    else if (gameId === '2143') {
                        gameId = '401';
                    }
                }
                
                if(gameId === '366' || gameId === '367'){
                  this.showLuckyDraw = true;
                }
                if(gameId === '407' || gameId === '424'){
                  this.showGameSpeed = true;
                }else {
                  this.showGameSpeed = this.showJsButton === '1';
                }

                let speed = localStorage.getItem('game_speed') || '1';
                let haveRead = localStorage.getItem("haveRead");
                if(!haveRead){
                  haveRead = [];
                }
                
                let soundset = localStorage.getItem('soundset');
                let speedtype = localStorage.getItem('speedtype');
                let selected = JSON.parse(localStorage.getItem('select_role'));
                
                localStorage.clear();

                localStorage.setItem('game_speed', speed);

                localStorage.setItem("haveRead",haveRead);

                localStorage.setItem("soundset",soundset);
                localStorage.setItem("speedtype",speedtype);
                localStorage.setItem("select_role",JSON.stringify(selected));
                
                localStorage.setItem('from', this.from);
                localStorage.setItem('token', token);
                localStorage.setItem('gameId', gameId);
                localStorage.setItem('gameJs', this.gameJs);
            },
            initPage() {
                let options = {
                    url:"/sdkH5/getLoginInfo",
                    data: {}
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.userInfo = res.data.data;
                        this.qlb = parseFloat(this.userInfo.qlBalance);
                        this.userName = this.userInfo.userName;
                        this.uid = this.userInfo.userId;
                        this.headerImg = this.userInfo.headerImg || '';
                        this.serverMsg = this.userInfo.serverMsg;
                        if (this.userInfo.phone) {
                            this.tel = this.userInfo.phone;
                            localStorage.setItem('phone', this.userInfo.phone)
                        }

                        localStorage.setItem('userInfo', this.userInfo)
                        localStorage.setItem('userId', this.uid)
                        localStorage.setItem('headerImg', this.headerImg)
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            getFlByCode() {
                if (!this.welfareCode) {
                    Toast('请输入七里福利码');
                    return;
                }
                this.showLoading = true;
                let options = {
                    url:"/sdkH5/welfareGet",
                    data: {
                        welfareCode: this.welfareCode
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.welfareCode = '';
                        Toast('礼包码兑换成功');
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            close() {
                notice.close();
            },
            goPage(tag) {
                switch (tag) {
                    case 'tel':
                        if (!this.tel) {
                            this.$router.push("/sdk/tel");
                        }
                        else {
                            this.$router.push("/sdk/telUnbind");
                        }
                        break;
                    case 'password':
                        this.$router.push("/sdk/password");break;
                    case 'weixin':
                        this.$router.push("/sdk/weixin");break;
                    case 'kf':
                        this.$router.push("/sdk/kf");break;
                    case 'trade':
                        this.$router.push("/sdk/trade");break;
                    case 'recharge':
                        this.$router.push("/sdk/recharge");break;
                    case 'app':
                        this.$router.push("/sdk/qiliApp");break;
                    case 'gift':
                        this.$router.push("/sdk/gift");break;
                    case 'record':
                        this.$router.push('/sdk/record');break;
                    case 'activity': 
                        this.$router.push('/sdk/content/list/2');break;
                    case 'strategy':
                        this.$router.push('/sdk/content/list/1');break;
                    case 'jgg':
                        this.$router.push('/shj/jgg');
                        break;
                  case 'gamespeed':
                        this.$router.push('/sdk/gamespeed');break;
                }
            },
            logout() {
                Dialog.confirm({
                    message: '确认要退出登录吗?',
                }).then(() => {
                    this.exit();
                    localStorage.clear();
                })
                .catch(() => {
                });
            },
            exit() {
                notice.logout();
            },
        }
    }
</script>

<style scoped>
    .all-view {
        width: 100%;
        height: 100vh;
        box-sizing: border-box;
        overflow: hidden;
        max-width: 400px;
        margin: 0 auto;
        background-color: #f8f8f8;
        border-radius: 5px;
    }
    .main-page-title {
        position: relative;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: flex-start;
        background-color: #4f6ed2;
    }
    .top-info-view {
        height: 50px;
        padding: 0 10px;
        margin-left: 10px;
        font-size: 12px;
        color: #ffffff;
        background-color: rgba(0,0,0,.1);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .main-body{
        display: flex;
        flex-direction: column;
        padding: 10px 10px 5px;
        box-sizing: border-box;
        position: relative;
        height: calc(100vh - 60px);
    }
    .menu-view {
        background-color: #ffffff;
        border-radius: 5px;
        max-height: calc(100vh - 115px);
        overflow: hidden;
        overflow-y: auto;
        -ms-overflow-style: none;  /* IE 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .menu-view::-webkit-scrollbar {
        display: none;
    }

    .bottom-view {
        width: calc(100vw - 20px);
        max-width: 380px;
        background-color: #ffffff;
        color: #888888;
        margin-top: 5px;
        border-radius: 5px;
        font-size: 10px;
        padding: 0 10px;
        box-sizing: border-box;
        height: 35px;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 5px;
        justify-content: space-between;
    }
    .menu-img-view {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }












    .left-view {
        height: 230px;
        width: 32%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 10px;
        position: relative;
    }
    .user-base-text {
        font-size: 12px;
        color: #666666;
        display: block;
    }
    .left-text {
        font-size: 10px;
        background: linear-gradient(0deg, #2bba06 4.248046875%, #03b134 49.0478515625%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .left-button {
        padding: 4px 14px;
        border-radius: 3px;
        font-size: 12px;
        color: #f3f3f3;
        background-color: #0263CC;
        user-select: none;
    }
    .exit-button {
        background-color: #EE3F14;
    }
    .right-view {
        flex: 1;
        position: relative;
        height: 230px;
    }
    .main-menu {
        padding: 5px;
        box-sizing: border-box;
        width: 60px;
        height: 50px;
        background-color: #F6A956;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        font-family: myFont,serif;
        color: #C32A1F;
    }
    .second-button {
        width: 100px;
        height: 35px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-family: myFont,serif;
        background-color: #ea7815;
        color: #ffffff;
    }
    .exchange-button {
        width: 60px;
        height: 26px;
        line-height: 26px;
        border: 2px solid #1558dc;
        color: #1558dc;
        font-family: myFont,serif;
        border-radius: 3px;

    }


    .back-input{
        flex: 1;
        height: 40px;
        display: flex;
        align-items: center;
    }
    .back-input .van-cell {
        padding: 1px 10px;
    }
    /deep/.back-input .van-field__control {
        color: #111111;
    }

    /deep/.van-grid-item__content {
        background-color: unset;
        padding: 12px 8px;
    }
</style>